import React from "react";
import { Route, Routes } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import Login from "views/Login";
import RecoveryPasswordView from "views/auth/RecoveryPasswordView";
import GoogleOauth from "../views/auth/GoogleOauth";
import HorizontalSpace from "../components/HorizontalSpace";
import CreateUser from "../views/auth/CreateUser";

const Auth = (props) => {
  React.useEffect(() => {
    document.documentElement.classList.add("auth-page");
    return () => {
      document.documentElement.classList.remove("auth-page");
    };
  }, []);

  return (
      <>
        <Container id={"login-page"} className={"main-content d-flex vh-100"} style={{width: "100%"}}>
          {/*<h1 id={"auth-logo"} style={{*/}
          {/*  position: "fixed",*/}
          {/*  top: '50px',*/}
          {/*  left: '50%',*/}
          {/*  width: '200px',*/}
          {/*  marginLeft: '-100px',*/}
          {/*}}>*/}
          {/*  <a href={"/"}>*/}
          {/*    <img*/}
          {/*        alt="Solução 42"*/}
          {/*        src={require("../assets/img/brand/logo-s42-white.png")}*/}
          {/*    />*/}
          {/*  </a>*/}
          {/*</h1>*/}

          <Row className="align-items-center justify-content-center" style={{width: "100%", margin: "0"}}>
            <Col className="text-center" sm={12}>
              {/*<Row>*/}
              {/*  <Col sm={12}>*/}
              {/*    <h1 id={"auth-logo"}>*/}
              {/*      <a href={"/"}>*/}
              {/*        <img*/}
              {/*            alt="Solução 42"*/}
              {/*            src={require("../assets/img/brand/logo-s42-white.png")}*/}
              {/*        />*/}
              {/*      </a>*/}
              {/*    </h1>*/}
              {/*  </Col>*/}
              {/*</Row>*/}

              <h1 id={"auth-logo"} style={{position: 'absolute', width: '150px', left: '50%', top: '-60px', marginLeft: '-75px'}}>
                <a href={"/"}>
                  <img
                      alt="Solução 42"
                      src={require("../assets/img/brand/logo-s42-white.png")}
                  />
                </a>
              </h1>

              <Row className={"justify-content-center"}>
                <Routes>
                  <Route path="login" element={<Login/>} key={1}/>
                  <Route path="create" element={<CreateUser/>} key={2}/>
                  <Route path="recovery/:token" element={<RecoveryPasswordView/>} key={3}/>
                  <Route path={"google"} element={<GoogleOauth/>} key={4}/>
                </Routes>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
  );
};

export default Auth;
