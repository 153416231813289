import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col, CardHeader,
} from "reactstrap";
  import { useState } from "react";
  import AuthClient from "services/auth";
import { useParams } from "react-router-dom";
import MeClient from "services/me";
  
  
  const RecoveryPasswordView = () => {
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [password, setPassword] = useState("");

    const { token } = useParams();

    const login = async () => {
      try {
        const auth = new AuthClient();

        if(await auth.recovery(token, password)) {
          const me = new MeClient();
          await me.getInfo();
          setInvalidLogin(false);
          window.location.href = "/switch_account";
          return;
        }
        setInvalidLogin(true);
      } catch (error) {
        setInvalidLogin(true);
      }
    };
  
    return (
      <>
        <Col lg="4" md="7">
          <Card className="bg-secondary shadow border-0">
              <CardHeader>
                  <span>Digite a sua nova senha</span>
              </CardHeader>
              <CardBody className="px-lg-4 py-lg-4">
              <Form role="form" onSubmit={login}>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Senha"
                      type="password"
                      required={true}
                      minLength={8}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className=""
                    color="primary"
                    type="submit"
                    style={{width: "100%"}}
                  >
                    Definir Senha
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };
  
  export default RecoveryPasswordView;
  