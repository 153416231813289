/*eslint-disable*/
import {NavLink as NavLinkRRD, Link, useLocation} from "react-router-dom";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav, Button,
} from "reactstrap";
import {Divider} from "antd";
import AuthClient from "../../services/auth";
import {useState} from "react";

var ps;

const checkIncludes = (paths) => {
  const href = window.location.href;
  for(let i = 0; i < paths.length; i++) {
    if(href.includes(paths[i])) return true;
  }
  return false;
}

const Sidebar = () => {
  function logout () {
    new AuthClient().logout();
    window.location.href = "/"
  }

  const [adminIsActive, setAdminIsActive] = useState(checkIncludes(['/api_keys', '/users', '/configurations', '/billings']));
  const [consumeIsActive, setConsumeIsActive] = useState(checkIncludes(['/channels', '/alarms', '/incidents']));
  const [apmIsActive, setApmIsActive] = useState(checkIncludes(['/services', '/traces']));

  const getCurrentTracePath = () => {
    return "/traces?" + document.location.href.split("?")[1]
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
      style={{paddingTop: '30px', borderRight: '1px solid #ccc', boxShadow: 'none !important'}}
    >
      <Collapse navbar isOpen={true} style={{paddingTop: 0}}>
        <Nav navbar>
          <NavItem key={1}  className={apmIsActive ? "active" : ""}>
            <NavLink to={"#"} onClick={() => setApmIsActive(!apmIsActive)} style={{cursor: "pointer"}}>
              <i className="fa-solid fa-cubes"></i>
              <span>APM</span>
            </NavLink>
            <Nav>
              <NavItem key={1}>
                <NavLink to={"/services"} tag={NavLinkRRD}>
                  <i className={"fa-solid fa-chart-simple"}></i>
                  <span>Serviços</span>
                </NavLink>
              </NavItem>

              {/*<NavItem key={2}>*/}
              {/*<NavLink to={"/traces"} tag={NavLinkRRD}>*/}
              {/*<i className="fa-solid fa-user-secret"></i>*/}
              {/*<span>Traces</span>*/}
              {/*</NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </NavItem>

          <NavItem key={2}  className={consumeIsActive ? "active" : ""}>
            <NavLink to={"#"} onClick={() => setConsumeIsActive(!consumeIsActive)} style={{cursor: "pointer"}}>
              <i className="fa-brands fa-watchman-monitoring"></i>
              <span>Monitoramento</span>
            </NavLink>

            <Nav>
              <NavItem key={3}>
                <NavLink to={"/channels"} tag={NavLinkRRD}>
                  <i className="fa-solid fa-envelopes-bulk"></i>
                  <span>Canais</span>
                </NavLink>
              </NavItem>

              <NavItem key={4}>
                <NavLink to={"/alarms"} tag={NavLinkRRD}>
                  <i className="fa-solid fa-bell"></i>
                  <span>Alarmes</span>
                </NavLink>
              </NavItem>

              <NavItem key={5}>
                <NavLink to={"/incidents"} tag={NavLinkRRD}>
                  <i className="fa-solid fa-triangle-exclamation"></i>
                  <span>Incidentes</span>
                </NavLink>
              </NavItem>
            </Nav>
          </NavItem>

          <NavItem key={3}  className={adminIsActive ? "active" : ""}>
            <NavLink to={"#"} onClick={() => setAdminIsActive(!adminIsActive)} style={{cursor: "pointer"}}>
              <i className="fa-solid fa-gears"></i>
              <span>Administração</span>
            </NavLink>

            <Nav>
              <NavItem key={1}>
                <NavLink to={"/api_keys"} tag={NavLinkRRD}>
                  <i className={"fa-solid fa-key"}></i>
                  <span>Api Keys</span>
                </NavLink>
              </NavItem>

              <NavItem key={2}>
                <NavLink to={"/users"} tag={NavLinkRRD}>
                  <i className="fa-solid fa-users"></i>
                  <span>Usuários</span>
                </NavLink>
              </NavItem>

              <NavItem key={3}>
                <NavLink to={"/configurations/consumption"} tag={NavLinkRRD}>
                  <i className="fa-solid fa-hard-drive"></i>
                  <span>Consumo</span>
                </NavLink>
              </NavItem>

              {/*<NavItem key={4}>*/}
              {/*  <NavLink to={"/configurations/billing"} tag={NavLinkRRD}>*/}
              {/*    <i className="fa-solid fa-money-bill"></i>*/}
              {/*    <span>Cobrança</span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}

              {/*<NavItem key={5}>*/}
              {/*  <NavLink to={"/configurations/payments"} tag={NavLinkRRD}>*/}
              {/*    <i className="fa-solid fa-credit-card"></i>*/}
              {/*    <span>Pagamento</span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
            </Nav>
          </NavItem>
        </Nav>
      </Collapse>

      <Divider />

      <div style={{margin: "0 20px"}}>
        <Button className={"btn-outline-dark btn-expand btn-small"} onClick={logout}>Sair</Button>
      </div>
    </Navbar>
  );
};

export default Sidebar;
