import {useEffect, useState} from 'react';
import {DatePicker} from "antd";
import {useSearchParams} from "react-router-dom";
import dayjs from 'dayjs';
import {Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup} from "reactstrap";
import {useGlobalState} from "../GlobalState";

const relative_lavel = {
    min: 'Min',
    hour: 'Horas',
    day: 'Dias'
}

function getNewStartAt() {
    const defaultStatAt = new Date();
    defaultStatAt.setSeconds(0);
    defaultStatAt.setMilliseconds(0);
    defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 15);
    return defaultStatAt
}

function GlobalDatePicker() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [relativeValue, setRelativeValue] = useGlobalState('rv'); // useState(15);
    const [aggregateUnit, setAggregateUnit] = useGlobalState('au'); // useState('min');
    const [aggregateValue, setAggregateValue] =  useGlobalState('av'); // useState(1);
    const [relativeUnit, setRelativeUnit] = useGlobalState('ru'); //useState(searchParams.get("relativeUnit") || "min");
    const [dateType, setDateType] = useGlobalState('dt'); //useState(searchParams.get("dateType") || 'relative');
    const [startAt, setStartAt] = useGlobalState('st'); // useState(parseInt(searchParams.get("startAt")) ||  dayjs(defaultStatAt).unix());
    const [endAt, setEndAt] = useGlobalState('et'); // useState(parseInt(searchParams.get("endAt")) || dayjs(new Date()).unix());

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenGroup, setDropdownOpenGroup] = useState(false);
    const [dropdownOpenGroupValue, setDropdownOpenGroupValue] = useState(false);


    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleDropdownGroup = () => {
        setDropdownOpenGroup(!dropdownOpenGroup);
    };

    const toggleDropdownGroupValue = () => {
        setDropdownOpenGroupValue(!dropdownOpenGroupValue);
    }

    const getRelativeName = () => {
        return relative_lavel[relativeUnit]
    }

    const getAggregateName = () => {
        return relative_lavel[aggregateUnit];
    }

    const setRelativeUnitHandler = (unit) => () => {
        setRelativeUnit(unit);
        setDropdownOpen(false);
        searchParams.set('ru', unit);
        setSearchParams(searchParams);
    };

    const toggleDropdownGroupHandler = (unit)  => () => {
        setAggregateUnit(unit);
        searchParams.set('au', unit);
        setSearchParams(searchParams);
        setDropdownOpenGroup(false);
    }

    const toggleDropdownGroupValueHandler = (unit) => () => {
        setAggregateValue(unit);
        setDropdownOpenGroupValue(false);
        searchParams.set('au', unit);
        setSearchParams(searchParams);
    }

    const setRelativeValueHandler = (e) => {
        setRelativeValue(e.target.value);
        searchParams.set('rv', e.target.value);
        setSearchParams(searchParams);
    }

    const handleButtonClick = (type) => {
        setDateType(type);
        searchParams.set('dt', type);
        setSearchParams(searchParams);
    };

    const onChange = (date) => {
        setStartAt(date[0].unix());
        setEndAt(date[1].unix());
        searchParams.set('st', date[0].unix());
        searchParams.set('et', date[1].unix());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const _dataType = searchParams.get("dt") || "relative";
        const _relativeUnit = searchParams.get("ru") || "min";
        const _relativeValue = parseInt(searchParams.get("rv")) || 15;
        const _startAt = parseInt(searchParams.get("st")) || dayjs(getNewStartAt()).unix();
        const _endAt = parseInt(searchParams.get("et")) || dayjs(new Date()).unix();
        const _aggregateUnit = searchParams.get('au') || 'min';
        const _aggregateValue = parseInt(searchParams.get('av')) || 1;

        if(_dataType !== dateType) setDateType(_dataType);

        if(_relativeUnit !== relativeUnit) setRelativeUnit(_relativeUnit)
        if(_relativeValue !== relativeValue) setRelativeValue(_relativeValue)

        if(_startAt !== startAt) setStartAt(_startAt)
        if(_endAt !== endAt) setEndAt(_endAt)

        if(_aggregateUnit !== aggregateUnit) setAggregateUnit(_aggregateUnit)
        if(_aggregateValue !== aggregateValue) setAggregateValue(_aggregateValue)

        searchParams.set('dt', _dataType);
        searchParams.set('ru', _relativeUnit);
        searchParams.set('rv', _relativeValue);
        searchParams.set('st', _startAt);
        searchParams.set('et', _endAt);
        searchParams.set('au', _aggregateUnit);
        setSearchParams(searchParams);
    }, []);

    return (
        <div className={"d-flex justify-content-between align-items-end clock-area"}>
            <ButtonGroup>
                <Button className={dateType === 'fixed' ? 'btn btn-default' : 'btn btn-secondary'}
                        onClick={() => handleButtonClick('fixed')}>
                    Fixo
                </Button>
                <Button className={dateType === 'relative' ? 'btn btn-default' : 'btn btn-secondary'}
                        onClick={() => handleButtonClick('relative')}>
                    Relativo
                </Button>
            </ButtonGroup>

            {dateType === 'fixed' && (
                <DatePicker.RangePicker showTime={{format: 'HH:mm'}}
                                        format="DD-MM-YYYY HH:mm"
                                        size={"large"}
                                        onChange={onChange}
                                        defaultValue={[dayjs.unix(startAt), dayjs.unix(endAt)]}
                                        separator={"/"}
                                        style={{
                                            'width': '280px',
                                            padding: '5px 10px',
                                            height: '36px',
                                            marginLeft: '10px',
                                            boxShadow: 'none',
                                            border: '1px solid #ccc'
                                        }}
                />
            )}

            {dateType === 'relative' && (
                <>
                    <div className={"relative-input-group"}>
                        <InputGroup style={{marginLeft: '10px'}}>
                            <Input className={"text-default"} type={"number"}
                                   style={{border: "0", "textAlign": "center", width: '50px'}} value={relativeValue}
                                   onChange={setRelativeValueHandler}/>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                                <DropdownToggle caret style={{borderRadius: "0 5px 5px 0", boxShadow: "none", height: '100%', border: '0'}}>
                                    {getRelativeName()}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem key={"min"} onClick={setRelativeUnitHandler("min")}>Min</DropdownItem>
                                    <DropdownItem key={"hour"}
                                                  onClick={setRelativeUnitHandler("hour")}>Hora</DropdownItem>
                                    <DropdownItem key={"day"} onClick={setRelativeUnitHandler("day")}>Dia</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </InputGroup>
                    </div>
                </>
            )}
        </div>
    );
}

export default GlobalDatePicker;