import AuthClient from "../../services/auth";
import {useEffect, useState} from "react";
import Login from "../Login";
import MeClient from "../../services/me";

const getQueryParam = (name) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
};

const auth = new AuthClient();

const GoogleOauth = () => {
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const code = getQueryParam('code');

        auth.googleOauth(code).then((success) => {
            setSuccess(true)
            const me = new MeClient();
            me.getInfo().then(() => {
                window.location.href = "/switch_account";
            })
            // setInvalidLogin(false);
        }).catch((error) => {
            console.log(error)
            setSuccess(false)
            window.location.href = "/auth/login";
        });

    }, []);

    return (
        <>
            {success === null && <div>Entrando...</div>}
            {success === false && <Login />}
        </>
    )
}

export default GoogleOauth;