/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { useState, useEffect } from "react";
import ServiceClient from "services/service";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import DefaultDatePicker from "../../components/date/DataPicker";
import {Line} from "react-chartjs-2";
import HorizontalSpace from "../../components/HorizontalSpace";
import Select from "react-select";
import {toast} from "react-toastify";
import {InfinitySpin} from "react-loader-spinner";

import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Chart,
    Filler
} from 'chart.js';

function getNewStartAt() {
    const defaultStatAt = new Date();
    defaultStatAt.setSeconds(0);
    defaultStatAt.setMilliseconds(0);
    defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 30);
    defaultStatAt.setMinutes(0)
    return defaultStatAt
}

function getEndDate() {
    const d = new Date()
    d.setSeconds(0);
    d.setMilliseconds(0);
    d.setMinutes(59);
    return d
}

function min(values) {
    return values.reduce((a, b) => Math.min(a, b));
}

function max(values) {
    return values.reduce((a, b) => Math.max(a, b));
}

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

Array.prototype.max = function() {
    return Math.max.apply(null, this);
};

Array.prototype.min = function() {
    return Math.min.apply(null, this);
};

const serviceClient = new ServiceClient();

const ServiceView = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    const [service_name, setServiceName] = useState(searchParams.get("serviceName") || "");
    const [serviceOptions, setServiceOptions] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [transactionName, setTransactionName] = useState(decodeURIComponent(searchParams.get("transactionName") || "*"));

    const defaultStartAt = getNewStartAt()

    const [dateType, setDateType] = useState(searchParams.get("dateType") || 'relative');
    const [relativeUnit, setRelativeUnit] = useState(searchParams.get("relativeUnit") || 'min');
    const [relativeValue, setRelativeValue] = useState(parseInt(searchParams.get("relativeValue") || '30'));
    const [startAt, setStartAt] = useState( searchParams.get("startAt") ? dayjs.unix(parseInt(searchParams.get("startAt"))) : dayjs(defaultStartAt));
    const [endAt, setEndAt] = useState( searchParams.get("endAt") ? dayjs.unix(parseInt(searchParams.get("endAt"))) : dayjs(getEndDate()));

    const [reportExecutionMetrics, setReportExecutionMetrics] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [options, setOptions] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const [reportExecutionMetricsApdex, setReportExecutionMetricsApdex] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [optionsApdex, setOptionsApdex] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const [reportExecutionMetricsLatency, setReportExecutionMetricsLatency] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [optionsLatency, setOptionsLatency] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })
    const [reportExecutionMetricsErrors, setReportExecutionMetricsErrors] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [optionsErrors, setOptionsErrors] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const fetchData = async (params) => {
        if(!(params.service_name || service_name)) return;
        const newParams = {
            ...params,
            startAt: params.startAt.unix(),
            endAt: params.endAt.unix(),
        }

        return serviceClient.getReportTransactionResume(params.service_name || service_name, newParams).then((_rows) => {
            _rows.forEach(r => {
                r['total_requests'] = parseInt(r['total_requests'])
                r['total_success'] = parseInt(r['total_success'])
                r['total_error'] = parseInt(r['total_error'])
                r['error_rate'] = parseFloat(r['error_rate'])
                r['apdex_score'] = parseFloat(r['apdex_score'])
                r['avg_duration'] = parseFloat(r['avg_duration'])
            });


            setRows(_rows)
        })
    }

    const fetchDataTransaction = async (params) => {
        if(!(params.service_name || service_name)) return;
        return serviceClient.getReportExecutionMetrics(params.service_name || service_name, {
            ...params,
            startAt: params.startAt.unix(),
            endAt: params.endAt.unix(),
        }).then((data) => {
            data['total_count'] = data['total_count'].map(value => parseInt(value))
            data['total_success'] = data['total_success'].map(value => parseInt(value))
            data['total_error'] = data['total_error'].map(value => parseInt(value))
            data['total_duration'] = data['total_duration'].map(value => parseFloat(value))
            data['apdex_score'] = data['apdex_score'].map(value => parseFloat(value))

            setOptions({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: parseInt((min(data.total_count) * 0.9).toFixed(0)),
                        max: parseInt((max(data.total_count) * 1.1).toFixed(0)),
                        grid: {
                            display:false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                             callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        // display: false
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetrics({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'Execuções',
                        data: data['total_count'],
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            setOptionsLatency({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: 0,
                        max: parseInt((max(data['p99']) * 1.1).toFixed(0)),
                        grid: {
                            display:false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.dataset.label + " - " + tooltipItem.raw.toFixed(0) + 'ms'
                            }
                        }
                    },
                    legend: {
                        // display: false
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsLatency({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'p50',
                        data: data['p50'],
                        borderColor: 'Violet',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(238,130,238, 0.1)',
                        borderWidth: 1,
                        animation: false,
                    },
                    {
                        label: 'p95',
                        data: data['p95'],
                        borderColor: 'DodgerBlue',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgb(30,144,255,0.1)',
                        borderWidth: 1,
                        animation: false
                    },
                    {
                        label: 'p99',
                        data: data['p99'],
                        borderColor: 'orange',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(255,165,0, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            setOptionsApdex({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        // min: 0,
                        max: 1,
                        grid: {
                            display: false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsApdex({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'Apdex Score',
                        data: data['apdex_score'].map(apdex => parseFloat(apdex)),
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false,
                    }
                ]
            });

            setOptionsErrors({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: 0,
                        max: 100,
                        grid: {
                            display:false
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value.toFixed(0) + '%';
                            }
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return (tooltipItem.raw).toFixed(2) + '%'
                            }
                        }
                    },
                    legend: {
                        display: false
                        // position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsErrors({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        data: data['error_rate'].map(e => e * 100),
                        borderColor: 'red',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(238,130,238, 0.1)',
                        borderWidth: 1,
                        animation: false,
                    }
                ]
            });
        })
    }

    const fetchAll = (params, shouldLoadAllServices = true) => {
        setIsLoading(true);

        let getServicesPromise = new Promise(resolve => resolve([]));
        if(shouldLoadAllServices) {
            getServicesPromise = serviceClient.getAll(0, 1000, {
                dateType,
                relativeUnit,
                relativeValue,
                startAt: startAt.unix(),
                endAt: endAt.unix()
            }).then(({data}) => {
                setServiceOptions(data.map((service) => ({value: service.name, label: service.name})));
            });
        }

        Promise.all([
            getServicesPromise,
            fetchData(params),
            fetchDataTransaction(params)
        ]).then(() => {
            setIsLoading(false)
        })
    }

    const refresh = () => {
        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            service_name: service_name,
            transaction_name: transactionName
        };

        fetchAll(params);
    }

    const handleOnClick = (transaction_name) => {
        setTransactionName(transaction_name);

        searchParams.set("transactionName", encodeURIComponent(transaction_name))
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            transaction_name: encodeURIComponent(transaction_name)
        };

        fetchDataTransaction(params)
    }

    const setConfigurationHandler = ({
                                         dateType,
                                         relativeUnit,
                                         relativeValue,
                                         startAt,
                                         endAt
                                     }) => {
        setDateType(dateType);
        setRelativeUnit(relativeUnit);
        setRelativeValue(relativeValue);
        setStartAt(startAt);
        setEndAt(endAt);

        searchParams.set("dateType", dateType);
        searchParams.set("relativeUnit", relativeUnit);
        searchParams.set("relativeValue", relativeValue);
        searchParams.set("startAt", startAt.unix());
        searchParams.set("endAt", endAt.unix());
        setSearchParams(searchParams);

        fetchAll({
            dateType,
            relativeUnit,
            relativeValue,
            startAt,
            endAt
        })
    }

    const handleSelectService = (selectedOption) => {
        setTransactionName('*');
        setSelectedService(selectedOption);
        setServiceName(selectedOption.value);
        searchParams.set("transactionName", "*")
        searchParams.set("serviceName", selectedOption.value);
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            service_name: selectedOption.value,
            transaction_name: '*'
        };

        fetchAll(params);
    }

    function formatNumber(numero) {
        if(!numero) return 0;

        const mil = 1000;
        const milhao = 1000000;
        const bilhao = 1000000000;

        if (numero < mil) {
            return numero.toString();
        } else if (numero < milhao) {
            return `${(numero / mil).toFixed(2)}k`
        } else if (numero < bilhao) {
            return `${(numero / milhao).toFixed(2)}Mi`
        } else {
            return `${(numero / bilhao).toFixed(2)}Bi`
        }
    };

    const getTextColorByScore = (apdex_score) => {
        if(!apdex_score || apdex_score < 0.75) return 'text-danger';
        if(apdex_score < 0.85) return 'text-warning';
        if(apdex_score < 0.94) return 'text-info';
        return 'text-primary';
    }

  useEffect(() => {
      serviceClient.getAll(0, 1000, { dateType, relativeUnit, relativeValue, startAt, endAt } ).then(({ data }) => {
          setServiceOptions(data.map((service) => ({ value: service.name, label: service.name })));

          let _service = null;
          if(service_name) {
              _service = data.find((service) => service.name === service_name);
          }
          if(!_service) _service = data[0];

          if(_service?.name) {
              setServiceName(_service?.name || "");
              setSelectedService({ value: _service.name, label: _service.name });

              if (!searchParams.get("serviceName") || searchParams.get("serviceName") !== _service.name) {
                  searchParams.set("serviceName", _service.name)
              }

              if (!searchParams.get("transactionName") || searchParams.get("transactionName") !== transactionName) {
                  searchParams.set("transactionName", transactionName)
              }

              setSearchParams(searchParams);

              const params = {
                  relativeValue,
                  relativeUnit,
                  dateType,
                  startAt,
                  endAt,
                  service_name: _service.name,
                  transaction_name: transactionName
              };

              fetchAll(params, false);
          }
      });

  }, []);

    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className={"fa-solid fa-chart-simple"}></i> Serviços</h1>
                </Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col sm={6}>
                    <div style={{width: '400px', display: 'inline-block'}} id={"select-service"}>
                        <Select value={selectedService}
                                classNamePrefix="select"
                                options={serviceOptions} onChange={handleSelectService} />
                    </div>

                    {isLoading && (<InfinitySpin
                        visible={true}
                        width={"100"}
                        color="#4fa94d"
                        ariaLabel="infinity-spin-loading"
                    />)}
                </Col>
                <Col sm={6} className={"d-flex justify-content-end align-items-end"}>
                    <Button onClick={refresh} className={"btn-outline-primary shadow-none"} style={{"background": "#fefefe", height: '48px', position: 'relative', top: '-1px'}}><i className="fa-solid fa-refresh"></i></Button>
                    <DefaultDatePicker setConfiguration={setConfigurationHandler} _dateType={dateType} _startAt={startAt} _endAt={endAt} _relativeUnit={relativeUnit} _relativeValue={relativeValue}/>
                </Col>
            </Row>

            {!service_name && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhum serviço encontrado.</p>
                                <Button className={"btn-outline-primary"} onClick={() => toast.error("TODO: precisa ser implementado!")}>Adicionar Novo Serviço</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(rows.length === 0) && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhuma métrica encontrada para o período selecionado</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(service_name && rows.length > 0) && (
                <>
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardHeader>
                                    <span style={{fontSize: '12px', fontWeight: 'normal'}}>Transações</span>
                                </CardHeader>
                                <CardBody style={{padding: 0}}>
                                    <div id={"table-transactions"}>
                                        {rows.map((transaction, index) => (
                                            <div className={transactionName === transaction.name ? "active-row" : ""}
                                                onClick={() => handleOnClick(transaction.name)}>
                                                <span style={{
                                                    background: 'none',
                                                    boxShadow: 'none',
                                                    padding: 0,
                                                    fontWeight: 'normal',
                                                    textAlign: 'left'
                                                }}>{transaction.name}</span>

                                                <small style={{display: 'block'}}>Execuções {formatNumber(transaction.total_requests)} / Apdex <apdex class={getTextColorByScore(transaction.apdex_score)}>{transaction.apdex_score.toFixed(2)}</apdex> / Latência <latency class={getTextColorByScore(transaction.apdex_score)}>{transaction.p50.toFixed(2)}ms</latency></small>
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9} id={"services-charts"}>
                            <Row>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Apdex (0.5)
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 className={getTextColorByScore(rows.find(r => r.name === transactionName)?.apdex_score)}>{rows.find(r => r.name === transactionName)?.apdex_score?.toFixed(2)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Latência Média
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 className={getTextColorByScore(rows.find(r => r.name === transactionName)?.apdex_score)}>{rows.find(r => r.name === transactionName)?.avg_duration?.toFixed(2)}ms</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Total Execuções
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 style={{color: '#333'}}>{formatNumber(rows.find(r => r.name === transactionName)?.total)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            % de Erros
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 style={{color: '#333'}}>{(rows.find(r => r.name === transactionName)?.error_rate * 100)?.toFixed(2)}%</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <HorizontalSpace/>

                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Latência
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsLatency} options={optionsLatency}/>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Execuções por Minuto
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetrics} options={options}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <HorizontalSpace/>

                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Apdex (0.5s)
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsApdex} options={optionsApdex}/>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Erros %
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsErrors} options={optionsErrors}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default ServiceView;
