import React from "react";
import {useLocation, Route, Routes, Navigate} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import ServiceView from "views/services";
import ApiKeysView from "views/api-key/Index";
import SwitchAccountView from "views/auth/SwitchAccountView";
import ApiKeysNewView from "../views/api-key/new";
import ChannelIndexView from "../views/channels";
import ChannelFormView from "../views/channels/form";
import AlarmIndexView from "../views/alarms";
import AlarmFormView from "../views/alarms/form";
import IncidentIndexView from "../views/incidents";
import UserIndex from "../views/users/UserIndex";
import ConsumptionView from "../views/configurations/ConsumptionView";
import TraceIndex from "../views/traces/TraceIndex";
import BillingIndex from "../views/billing/BillingIndex";
import PaymentIndex from "../views/payment/PaymentIndex";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          brandText={getBrandText(props?.location?.pathname)}
        />

        <Sidebar  />

        <div style={{marginLeft: '200px', marginTop: '80px'}}>
          <Container fluid id={"main-container"}>
            <Routes>
              {/*<Route path='/' element={<ServiceView/>} exact/>*/}
              <Route path='/switch_account' element={<SwitchAccountView/>} exact/>
              <Route path='/services' element={<ServiceView/>} exact/>
              {/*<Route path='/services/:service_name' element={<ServiceView/>} exact/>*/}
              <Route path='/traces' element={<TraceIndex/>} exact/>
              {/*<Route path='/service/:service_id/transactions/:transaction' element={<ServiceTransactionShowView/>} exact/>*/}
              <Route path='/api_keys/new' element={<ApiKeysNewView/>} exact/>
              <Route path='/api_keys' element={<ApiKeysView/>} exact/>
              <Route path='/channels' element={<ChannelIndexView />} exact/>
              <Route path='/channels/new' element={<ChannelFormView />} exact/>
              <Route path='/channels/:id/edit' element={<ChannelFormView />} exact/>
              <Route path='/alarms' element={<AlarmIndexView />} exact/>
              <Route path='/alarms/new' element={<AlarmFormView />} exact/>
              <Route path='/alarms/:id/edit' element={<AlarmFormView />} exact/>
              <Route path='/incidents' element={<IncidentIndexView />} exact/>
              <Route path='/users' element={<UserIndex />} exact/>
              <Route path='/configurations/consumption' element={<ConsumptionView />} exact/>
              <Route path='/configurations/billing' element={<BillingIndex/>} exact/>
              <Route path='/configurations/payments' element={<PaymentIndex/>} exact/>
              <Route
                  path="*"
                  element={<Navigate to="/services" replace />}
              />
            </Routes>
          </Container>
        </div>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
