import ApiClient from "../clients/api";

class UserClient extends ApiClient {
    constructor() {
        super();
    }

    getAll(page = 0, limit = 10, query = {}) {
        try {
            page = page === 0 ? 0 : page - 1;
            let queryString = new URLSearchParams(query).toString();
            return this.get(
                `api/users?page=${page}&per_page=${limit}&count=true&${queryString}`
            );
        } catch (error) {
            return [];
        }
    }

    update(user_id, params) {
        return this.patch(`api/users/${user_id}`, params);
    }

    destroy(user_id) {
        return this.delete(`api/users/${user_id}`);
    }

    create({ email, role }) {
        return this.post('api/users/invite_user', { email, role })
    }
}

export default UserClient;
