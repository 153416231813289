import ApiClient from "../clients/api";

class AuthClient extends ApiClient {
  constructor() {
    super();
  }

  async logout() {
    localStorage.removeItem('current_account_id');
    localStorage.removeItem('current_account');
    localStorage.removeItem("token");
  }

  async createUser(username) {
    return this.post("api/auth/create_user", {
      username,
    })
  }

  async login(username, password) {
    try {
      const data = await this.post("api/auth", {
        username,
        password,
      });
      if (!data.token) {
        return false;
      }

      const token = data.token;
      localStorage.setItem("token", token);
      localStorage.removeItem('current_account_id');
      localStorage.removeItem('current_account');
      localStorage.setItem("logged_at", new Date().getTime());
      return true;
    } catch (error) {
      return false;
    }
  }

  async recovery(recoveryToken, password) {
    try {
      const data = await this.post(`api/auth/recovery/` + recoveryToken, {
        password,
      });

      if (!data.token) {
        return false;
      }
      const token = data.token;
      localStorage.removeItem('current_account_id');
      localStorage.removeItem('current_account');
      localStorage.setItem("token", token);
      localStorage.setItem("logged_at", new Date().getTime());
      return true;
    } catch (error) {
      return false;
    }
  }

    async googleOauth(code) {
      try {
        const data = await this.get(`api/auth/google?code=${code}`);

        if (!data.token) {
          return false;
        }
        const token = data.token;
        localStorage.removeItem('current_account_id');
        localStorage.removeItem('current_account');
        localStorage.setItem("token", token);
        localStorage.setItem("logged_at", new Date().getTime());
        return true;
      } catch (error) {
        return false;
      }
    }
}

export default AuthClient;
