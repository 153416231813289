/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Table, Input
} from "reactstrap";
import { useState, useEffect } from "react";
import ServiceClient from "services/service";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import DefaultDatePicker from "../../components/date/DataPicker";
import {Line} from "react-chartjs-2";
import HorizontalSpace from "../../components/HorizontalSpace";
import Select from "react-select";
import {toast} from "react-toastify";
import {InfinitySpin} from "react-loader-spinner";
import Paginate from "../../components/Paginate";

const getNewStartAt = () => {
    const defaultStatAt = new Date();
    defaultStatAt.setSeconds(0);
    defaultStatAt.setMilliseconds(0);
    defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 30);
    return defaultStatAt
}

const formatNano = (tempoNano) => {
    if (tempoNano < 1000) {
        return tempoNano + "ns";
    } else if (tempoNano < 1000000) {
        return (tempoNano / 1000).toFixed(2) + "µs";
    } else if (tempoNano < 1000000000) {
        return (tempoNano / 1000000).toFixed(2) + "ms";
    } else {
        return (tempoNano / 1000000000).toFixed(2) + "s";
    }
}

const TraceIndex = () => {
    const serviceClient = new ServiceClient();
    let [searchParams, setSearchParams] = useSearchParams();

    const [service_name, setServiceName] = useState(searchParams.get("serviceName") || "");
    const [serviceOptions, setServiceOptions] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [transactionName, setTransactionName] = useState(decodeURIComponent(searchParams.get("transactionName") || "*"));

    const defaultStartAt = getNewStartAt()

    const [query, setQuery] = useState(decodeURIComponent(searchParams.get("query") || ""));
    const [dateType, setDateType] = useState(searchParams.get("dateType") || 'relative');
    const [relativeUnit, setRelativeUnit] = useState(searchParams.get("relativeUnit") || 'min');
    const [relativeValue, setRelativeValue] = useState(parseInt(searchParams.get("relativeValue") || '30'));
    const [startAt, setStartAt] = useState( searchParams.get("startAt") ? dayjs.unix(parseInt(searchParams.get("startAt"))) : dayjs(defaultStartAt));
    const [endAt, setEndAt] = useState( searchParams.get("endAt") ? dayjs.unix(parseInt(searchParams.get("endAt"))) : dayjs(new Date()));

    const perPageOptions = [
        { value: 20, label: '20' },
        { value: 50, label: '50' },
        { value: 100, label: '100' }
    ]

    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get("currentPage") || '1'));
    const [perPage, setPerPage] = useState(parseInt(searchParams.get("perPage") || '20'));
    const [transactions, setTransactions] = useState([]);
    const [transactionsTotal, setTransactionsTotal] = useState(0);
    const [activeIndexes, setActiveIndexes] = useState(new Set())
    const [selectPerPage, setSelectPerPage] = useState(perPageOptions.find((option) => option.value === perPage));

    const handleQuery = (e) => {
        setQuery(e.target.value);
        setCurrentPage(1);

        searchParams.set("currentPage", "1");
        searchParams.set("query", encodeURIComponent(e.target.value));
        setSearchParams(searchParams);

        fetchAll({ query: e.target.value, currentPage: 1 })
    }

    const handleSelectedPerPage = (option) => {
        if( option.value === perPage ) return;

        setSelectPerPage(option);
        setPerPage(option.value);
        searchParams.set("perPage", option.value)
        setSearchParams(searchParams);

        fetchAll({ perPage: option.value });
    }

    const handleCurrentPage = (page) => {
        if(page === currentPage) return;

        setCurrentPage(page);
        searchParams.set("currentPage", page)
        setSearchParams(searchParams);

        fetchAll({ page: parseInt(page)-1 });
    }

    const toggleIndex = (index) => {
        if(activeIndexes.has(index)) activeIndexes.delete(index);
        else {
            activeIndexes.add(index)

            serviceClient.getTransactionsSpans(service_name, {
                relativeValue,
                relativeUnit,
                dateType,
                startAt,
                endAt,
                service_name: service_name,
                trace_id: transactions[index].trace_id
            }).then(data => {
                transactions[index].spans = data
                setTransactions([...transactions])
            });
        };

        setActiveIndexes(new Set(activeIndexes));
    }

    const isActiveIndex = (index) => {
        if(activeIndexes.has(index)) return 'active';
        return ''
    }

    const fetchData = async (params) => {
        if(!(params.service_name || service_name)) return;
        return serviceClient.getReportTransactionResume(params.service_name || service_name, {
            ...params,
            startAt: params.startAt.unix() + params.startAt.utcOffset() * -60,
            endAt: params.endAt.unix() + params.endAt.utcOffset() * -60,
        }).then(setRows)
    }

    const fetchDataTransaction = async (params) => {
        if(!(params.service_name || service_name)) return;
        return serviceClient.getTransactions(params.service_name || service_name, {
            ...params,
            page: params.currentPage-1,
            per_page: params.perPage,
            startAt: params.startAt.unix() + params.startAt.utcOffset() * -60,
            endAt: params.endAt.unix() + params.endAt.utcOffset() * -60,
        }).then((data) => {
            setTransactionsTotal(data.total);
            setTransactions(data.data)
        })
    }

    const fetchAll = (params) => {
        setIsLoading(true);
        setActiveIndexes(new Set());

        const allParams = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            currentPage,
            perPage,
            service_name: service_name,
            transaction_name: transactionName,
            query,
            ...params
        }

        console.log(allParams, currentPage, params)

        Promise.all([
            fetchData(allParams),
            fetchDataTransaction(allParams)
        ]).then(() => {
            setIsLoading(false)
        })
    }

    const refresh = () => {
        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            currentPage,
            perPage,
            service_name: service_name,
            transaction_name: transactionName,
            query
        };

        fetchAll(params);
    }

    const handleOnClick = (transaction_name) => {
        setTransactionName(transaction_name);
        setCurrentPage(1);
        searchParams.set("currentPage", "1");
        searchParams.set("transactionName", encodeURIComponent(transaction_name))
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            currentPage: 1,
            perPage,
            transaction_name: encodeURIComponent(transaction_name),
            query
        };

        return fetchDataTransaction(params)
    }

    const setConfigurationHandler = ({
                                         dateType,
                                         relativeUnit,
                                         relativeValue,
                                         startAt,
                                         endAt
                                     }) => {
        setDateType(dateType);
        setRelativeUnit(relativeUnit);
        setRelativeValue(relativeValue);
        setStartAt(startAt);
        setEndAt(endAt);

        searchParams.set("dateType", dateType);
        searchParams.set("relativeUnit", relativeUnit);
        searchParams.set("relativeValue", relativeValue);
        searchParams.set("startAt", startAt.unix());
        searchParams.set("endAt", endAt.unix());
        setSearchParams(searchParams);

        fetchAll({
            dateType,
            relativeUnit,
            relativeValue,
            startAt,
            endAt
        })
    }

    const handleSelectService = (selectedOption) => {
        setTransactionName('*');
        setSelectedService(selectedOption);
        setServiceName(selectedOption.value);
        setCurrentPage(1);

        searchParams.set("currentPage", "1");
        searchParams.set("transactionName", "*")
        searchParams.set("serviceName", selectedOption.value);
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            currentPage: 1,
            perPage,
            service_name: selectedOption.value,
            transaction_name: '*'
        };

        fetchAll(params);
    }

    function formatNumber(numero) {
        if(!numero) return 0;

        const mil = 1000;
        const milhao = 1000000;
        const bilhao = 1000000000;

        if (numero < mil) {
            return numero.toString();
        } else if (numero < milhao) {
            return `${(numero / mil).toFixed(2)}k`
        } else if (numero < bilhao) {
            return `${(numero / milhao).toFixed(2)}Mi`
        } else {
            return `${(numero / bilhao).toFixed(2)}Bi`
        }
    };

    const getTextColorByScore = (apdex_score) => {
        if(!apdex_score || apdex_score < 0.75) return 'text-danger';
        if(apdex_score < 0.85) return 'text-warning';
        if(apdex_score < 0.94) return 'text-info';
        return 'text-primary';
    }

    useEffect(() => {
        serviceClient.getAll(0, 1000, { ignoreMetrics: true } ).then(({ data }) => {
            setServiceOptions(data.map((service) => ({ value: service.name, label: service.name })));

            let _service = null;
            if(service_name) {
                _service = data.find((service) => service.name === service_name);
            }
            if(!_service) _service = data[0];

            if(_service?.name) {
                setServiceName(_service?.name || "");
                setSelectedService({ value: _service.name, label: _service.name });

                if (!searchParams.get("serviceName") || searchParams.get("serviceName") !== _service.name) {
                    searchParams.set("serviceName", _service.name)
                }

                if (!searchParams.get("transactionName") || searchParams.get("transactionName") !== transactionName) {
                    searchParams.set("transactionName", transactionName)
                }

                setSearchParams(searchParams);

                const params = {
                    relativeValue,
                    relativeUnit,
                    dateType,
                    startAt,
                    endAt,
                    page: currentPage,
                    currentPage: currentPage,
                    service_name: _service.name,
                    transaction_name: transactionName
                };

                fetchAll(params);
            }
        });

    }, []);

    const getExceptions = (events) => {
        const allExceptions = [];
        for(const event of events) {
            if(event.name === 'exception') allExceptions.push({
                exceptionType: event.attributes.find((attr) => attr.key === 'exception.type')?.value?.value?.StringValue,
                exceptionMessage: event.attributes.find((attr) => attr.key === 'exception.message')?.value?.value?.StringValue,
                exceptionStackTrace: event.attributes.find((attr) => attr.key === 'exception.stacktrace')?.value?.value?.StringValue,
            })
        }
        return allExceptions;
    }

    const formatExceptionMessage = (events) => {
        const allExceptions = getExceptions(events);
        if(allExceptions.length === 0) return null;
        return `${allExceptions[0].exceptionType} - ${allExceptions[0].exceptionMessage}`;
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className="fa-solid fa-user-secret"></i> Traces</h1>
                </Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col sm={6}>
                    <div style={{width: '400px', display: 'inline-block'}} id={"select-service"}>
                        <Select value={selectedService}
                                classNamePrefix="select"
                                options={serviceOptions} onChange={handleSelectService} />
                    </div>

                    {isLoading && (<InfinitySpin
                        visible={true}
                        width={"100"}
                        color="#4fa94d"
                        ariaLabel="infinity-spin-loading"
                    />)}
                </Col>
                <Col sm={6} className={"d-flex justify-content-end align-items-end"}>
                    <Button onClick={refresh} className={"btn-outline-primary shadow-none"} style={{"background": "#fefefe", height: '48px', position: 'relative', top: '-1px'}}><i className="fa-solid fa-refresh"></i></Button>
                    <DefaultDatePicker setConfiguration={setConfigurationHandler} _dateType={dateType} _startAt={startAt} _endAt={endAt} _relativeUnit={relativeUnit} _relativeValue={relativeValue}/>
                </Col>
            </Row>

            {!service_name && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhum serviço encontrado.</p>
                                <Button className={"btn-outline-primary"} onClick={() => toast.error("TODO: precisa ser implementado!")}>Adicionar Novo Serviço</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(rows.length === 0) && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhuma métrica encontrada para o período selecionado</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(service_name && rows.length > 0) && (
                <>
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardHeader>
                                    Transações
                                </CardHeader>
                                <CardBody style={{padding: 0}}>
                                    <div id={"table-transactions"}>
                                        {rows.map((transaction, index) => (
                                            <div className={transactionName === transaction.name ? "active-row" : ""}
                                                 onClick={() => handleOnClick(transaction.name)}>
                                                <span style={{
                                                    background: 'none',
                                                    boxShadow: 'none',
                                                    padding: 0,
                                                    fontWeight: 'normal',
                                                    textAlign: 'left'
                                                }}>{transaction.name}</span>

                                                <small style={{display: 'block'}}>Execuções {formatNumber(transaction.total_requests)} / Apdex <apdex class={getTextColorByScore(transaction.apdex_score)}>{transaction.apdex_score.toFixed(2)}</apdex> / Latência <latency class={getTextColorByScore(transaction.apdex_score)}>{transaction.quantiles[0].toFixed(2)}ms</latency></small>
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9} id={"services-charts"}>
                            <Row>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody style={{textAlign: "center"}}>
                                            <Row>
                                                <Col sm={4}>
                                                    <Input value={query} placeholder={"Pesquisar"} style={{height: '39px', background: "#fff"}} onChange={handleQuery} />
                                                </Col>
                                                <Col sm={6}>
                                                    <Paginate currentPage={currentPage} perPage={20} totalItems={transactionsTotal} onChange={handleCurrentPage} />
                                                </Col>
                                                <Col sm={2}>
                                                    <Select value={selectPerPage}
                                                            classNamePrefix="select"
                                                            options={perPageOptions} onChange={handleSelectedPerPage} />
                                                </Col>
                                            </Row>

                                            <div id={"list-transactions-wrapper"}>
                                                <ul id={"list-transactions"}>
                                                    <li className={"list-transactions-item"}>
                                                        <div className={"list-transactions-item-header"}>
                                                            <table className={"table-header"}>
                                                                <tbody>
                                                                <tr>
                                                                    <td style={{width: 9}}></td>
                                                                    <td style={{width: '150px'}}>Data/Hora</td>
                                                                    <td style={{width: '80px'}}>Duração</td>
                                                                    <th style={{width: '150px'}}>Instrumentation</th>
                                                                    <td style={{
                                                                        textAlign: 'left',
                                                                        paddingLeft: '10px'
                                                                    }}>Transação
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </li>
                                                    {transactions.map((transaction, index) => (
                                                        <li className={"list-transactions-item " + isActiveIndex(index)}>
                                                            <div className={"list-transactions-item-header"}>
                                                                <table className={"table-header"}>
                                                                    <tbody>
                                                                    <tr onClick={() => toggleIndex(index)}>
                                                                        <td style={{width: 9}}>
                                                                            {!transaction.status_code &&
                                                                                <i className="fa-solid fa-circle-question"
                                                                                   style={{color: 'orange'}}></i>}

                                                                            {transaction.status_code === 1 &&
                                                                                <i className="fa-solid fa-check-circle"
                                                                                   style={{color: 'green'}}></i>}

                                                                            {transaction.status_code === 2 &&
                                                                                <i className="fa-solid fa-exclamation-triangle"
                                                                                   style={{color: 'red'}}
                                                                                   title={formatExceptionMessage(transaction.events) || transaction.status_message}></i>}
                                                                        </td>
                                                                        <td style={{width: '150px'}}>{new Date(transaction.ts).toLocaleString()}</td>
                                                                        <td style={{width: '80px'}}>{formatNano(transaction.consumed_time_nano)}</td>
                                                                        <td style={{
                                                                            width: '150px',
                                                                            whiteSpace: 'nowrap'
                                                                        }}
                                                                            title={transaction.instrumentation_name}>{transaction.instrumentation_name.replace("@opentelemetry/", '')}</td>
                                                                        <td style={{
                                                                            textAlign: 'left',
                                                                            paddingLeft: '10px'
                                                                        }}>{transaction.name}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className={"list-transactions-item-info"} style={{
                                                                textAlign: 'left',
                                                                wordWrap: 'break-word',
                                                                whiteSpace: 'break-spaces',
                                                            }}>
                                                                <div className={"exception-block-wrapper"}>
                                                                    {getExceptions(transaction.events).map(exception => (
                                                                        <div className={"exception-block"}>
                                                                            <h4 className={"exception-block-type"}>Exception: {exception.exceptionType} {exception.exceptionMessage}</h4>
                                                                            <textarea readOnly={true} className={"exception-block-stacktrace"} defaultValue={exception.exceptionStackTrace} />
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                                <HorizontalSpace />

                                                                <h3>Spans</h3>
                                                                <div className={isActiveIndex(index)}>
                                                                    {transaction.spans === undefined && (
                                                                        <InfinitySpin
                                                                            visible={true}
                                                                            width={"100"}
                                                                            color="#4fa94d"
                                                                            ariaLabel="infinity-spin-loading"
                                                                        />
                                                                    )}
                                                                    {transaction.spans?.length === 0 && (
                                                                        <small>Sem spans registrados</small>
                                                                    )}

                                                                    {!!transaction.spans?.length && (<table className={"table-attributes"}>
                                                                                                        <thead>
                                                                                                        <tr>
                                                                                                            <th style={{width: 0}}>Duração</th>
                                                                                                            <th style={{width: 0}}>Instrumentation</th>
                                                                                                            <th style={{width: 0, whiteSpace: 'nowrap'}}>Nome</th>
                                                                                                            <th>Atributos</th>
                                                                                                        </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                        {transaction.spans.map((item, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td style={{width: 0, verticalAlign: 'top'}}>{formatNano(item.consumed_time_nano)}</td>
                                                                                                                <td style={{width: 0, whiteSpace: 'nowrap', verticalAlign: 'top'}}>{item.instrumentation_name}</td>
                                                                                                                <td style={{width: 0, whiteSpace: 'nowrap', verticalAlign: 'top'}}>{item.name}</td>
                                                                                                                <td>
                                                                                                                    {item.attributes.map(att => (<>
                                                                                                                        <strong>{att.key}</strong>: {att.value.value.StringValue}<br/>
                                                                                                                    </>))}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        ))}
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                )}

                                                                </div>

                                                                <HorizontalSpace />

                                                                <h3>Atributos</h3>
                                                                <table className={"table-attributes"}>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Nome</th>
                                                                        <th>Valor</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {transaction.attributes.map((attr) => (
                                                                        <tr>
                                                                            <td>{attr.key}</td>
                                                                            <td>{attr.value.value.StringValue}</td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default TraceIndex;