import React, { useState, useEffect } from "react";
import Header from "components/Headers/Header";
import {Card, CardHeader, Col, Container, Row, Table, Button, CardBody} from "reactstrap";
import MeClient from "services/me";
import AuthClient from "services/auth";
import HorizontalSpace from "../../components/HorizontalSpace";

const SwitchAccountView = () => {
    const [accounts, setAccounts] = useState([]);
    const meClient = new MeClient();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await meClient.getAccounts();
                setAccounts(data);
            } catch (error) {
                console.error("Error fetching accounts:", error);
            }
        };

        fetchData();
    }, []);

    const switchAccount = async (accountId) => {
        const auth = new AuthClient();

        if(await meClient.switchAccount(accountId)) {
            localStorage.setItem('current_account_id', accountId);
            window.location.href = "/";
            return;
        }
    };

    return (
        <>
            <Container>
                <Row>
                    <Col sm={4}></Col>
                    <Col sm={4} style={{textAlign: 'center'}}>
                        <h1>Seleciona uma Conta</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6}>
                        {accounts.map((account) => (
                            <>
                                <Card>
                                    <CardBody>
                                        <span>{account.name}</span>
                                        <Button
                                            color="primary"
                                            className={"btn-small"}
                                            style={{float: 'right'}}
                                            onClick={() => switchAccount(account.id)}
                                        >Entrar</Button>
                                    </CardBody>
                                </Card>
                                <HorizontalSpace />
                            </>
                        ))}
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SwitchAccountView;
