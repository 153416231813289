import ApiKeysView from "views/api-key/Index";
import ServiceView from "views/services/index.js";

var routes = [
  {
    path: "/services",
    name: "Serviços",
    icon: "fa-solid fa-desktop text-primary",
    component: <ServiceView />,
    layout: "/",
    showInMenu: true,
  },
  {
    path: "/api_keys",
    name: "API Keys",
    icon: "fa-solid fa-key text-primary",
    component: <ApiKeysView />,
    layout: "/",
    showInMenu: true,
  }
];
export default routes;
