import {Col, Container, Row} from "reactstrap";
import HorizontalSpace from "../components/HorizontalSpace";

const PolicyLayout = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col sm={12}>
                        <HorizontalSpace/>

                        <h1 id={"auth-logo"}>
                            <a href={"/"}>
                                <img
                                    alt="Solução 42"
                                    src={require("../assets/img/brand/logo-s42-green.png")}
                                />
                            </a>
                        </h1>

                        <section>
                            <h2>1. Informações que Coletamos:</h2>
                            <p>1.1 <strong>Informações Pessoais:</strong> Podemos coletar informações pessoais que você
                                fornece diretamente, como nome, endereço de e-mail, número de telefone, entre outros,
                                quando você se cadastra ou utiliza nossos serviços.</p>
                            <p>1.2 <strong>Informações de Uso:</strong> Coletamos informações sobre a forma como você
                                interage com nossos serviços, como páginas acessadas, recursos utilizados, tempo de
                                navegação e outros dados de uso.</p>
                            <p>1.3 <strong>Informações de Dispositivo:</strong> Podemos coletar informações sobre o
                                dispositivo que você utiliza para acessar nossos serviços, incluindo endereço IP, tipo
                                de navegador, sistema operacional, identificadores de dispositivo e outras informações
                                similares.</p>
                        </section>

                        <section>
                            <h2>2. Como Usamos as Informações:</h2>
                            <p>2.1 <strong>Fornecimento de Serviços:</strong> Utilizamos as informações coletadas para
                                fornecer e melhorar nossos serviços, personalizar sua experiência, responder a
                                solicitações e oferecer suporte ao cliente.</p>
                        </section>

                        <section>
                            <h2>3. Compartilhamento de Informações:</h2>
                            <p>3.1 <strong>Parceiros e Prestadores de Serviço:</strong> Podemos compartilhar suas
                                informações com terceiros que prestam serviços em nosso nome, como processamento de
                                pagamentos, análise de dados e outros serviços necessários para operar nossos negócios.
                            </p>
                            <p>3.2 <strong>Requisitos Legais:</strong> Podemos divulgar suas informações se acreditarmos
                                que é necessário para cumprir com obrigações legais, proteger nossos direitos, prevenir
                                fraudes ou garantir a segurança de nossos usuários.</p>
                        </section>


                        <section>
                            <h2>4. Cookies e Tecnologias Semelhantes:</h2>
                            <p>Utilizamos cookies e tecnologias semelhantes para coletar informações sobre seu
                                comportamento de navegação e personalizar sua experiência. Você pode configurar seu
                                navegador para recusar cookies, mas isso pode afetar o funcionamento adequado de nossos
                                serviços.</p>
                        </section>

                        <section>
                            <h2>5. Segurança:</h2>
                            <p>Implementamos medidas de segurança para proteger suas informações contra acesso não
                                autorizado, alteração, divulgação ou destruição não autorizada.</p>
                        </section>

                        <section>
                            <h2>6. Seus Direitos:</h2>
                            <p>Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais.
                                Entre em contato conosco para exercer esses direitos.</p>
                        </section>

                        <section>
                            <h2>7. Alterações nesta Política:</h2>
                            <p>Reservamo-nos o direito de atualizar esta política periodicamente. Recomendamos que
                                revise esta política regularmente para ficar informado sobre como estamos protegendo
                                suas informações.</p>
                        </section>

                        <section>
                            <h2>8. Contato:</h2>
                            <p>Se tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato
                                conosco através dos canais disponibilizados em nossos serviços.</p>
                        </section>

                        <footer>
                            <p><em>Última atualização: 2023/12/28</em></p>
                        </footer>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PolicyLayout;