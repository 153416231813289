import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useState } from "react";

import AuthClient from "../services/auth";
import MeClient from "../services/me";
import {Link} from "react-router-dom";
import {Divider} from "antd";

const auth = new AuthClient();

const Login = () => {
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const google_url = `https://accounts.google.com/o/oauth2/auth?access_type=offline&approval_prompt=force&client_id=723317036515-l6i3tdqs081miq912a709gbuohdh1qbs.apps.googleusercontent.com&include_granted_scopes=true&redirect_uri=${process.env.REACT_APP_API_DOMAIN}auth/google&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20openid`

  const login = async () => {
    try {
      if (await auth.login(username, password)) {
        const me = new MeClient();
        await me.getInfo();
        setInvalidLogin(false);
        window.location.href = "/switch_account";
        return;
      }
      setInvalidLogin(true);
    } catch (error) {
      setInvalidLogin(true);
    }
  };

  return (
    <>
      <Col lg="4" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-4 py-lg-4">
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Usuário"
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Senha"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className=""
                  color="primary"
                  type="button"
                  onClick={login}
                  style={{width: "100%", marginBottom: '20px'}}
                >
                  Entrar
                </Button>

                <Link to={"/auth/create"}>
                  Criar Usuário
                </Link>

                <Divider />

                <Link
                    to={google_url}
                    className="btn btn-primary"
                    type="button"
                    style={{width: "100%", background: '#ea4335', border: '1px solid #ea4335'}}
                >
                  Entrar com Google
                </Link>
              </div>
              {invalidLogin ? (
                <Alert color="danger">Usuário ou senha inválidos.</Alert>
              ) : (
                ""
              )}
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Esqueci a senha</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Criar conta</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
