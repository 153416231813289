import ApiClient from "../clients/api";

class ReportsClient extends ApiClient {
    constructor() {
        super();
    }

    async getConsumption(query) {
        let queryString = new URLSearchParams(query).toString()
        return this.get(`api/reports/consumption?${queryString}`)
    }
}

export default ReportsClient;
