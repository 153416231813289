import ApiClient from "../clients/api";

class ServiceClient extends ApiClient {
  constructor() {
    super();
  }

  getAll(page = 0, limit = 10, query = {}) {
    try {
      page = page === 0 ? 0 : page - 1;
      let queryString = new URLSearchParams(query).toString();
      return this.get(
        `api/services?page=${page}&per_page=${limit}&count=true&${queryString}`
      );
    } catch (error) {
      return [];
    }
  }

  getById(id, queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`api/services/${id}?${queryString}`)
  }

  getReportExecutionMetrics(id, queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/services/${id}/report/execution_metrics?${queryString}`)
  }

  getReportTransactionResume(id, queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/services/${id}/report/transaction_resume?${queryString}`)
  }

  getTransactions(id, queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/services/${id}/transactions?${queryString}`)
  }

  getTransactionsSpans(id, queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/services/${id}/transactions_spans?${queryString}`)
  }
}

export default ServiceClient;
