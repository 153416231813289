import { createGlobalState } from 'react-hooks-global-state';
import dayjs from "dayjs";

const defaultStatAt = new Date();
defaultStatAt.setSeconds(0);
defaultStatAt.setMilliseconds(0);
defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 15);

const initialState = {
    dt: 'relative',
    ru: 'min',
    rv: 15,
    st: dayjs(defaultStatAt).unix(),
    et: dayjs(new Date()).unix(),
    au: 'min',
    av: 1
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
// const params = {};
const permittedKeys = Object.keys(initialState);

for (const [key, value] of urlParams.entries()) {
    if(permittedKeys.includes(key)) initialState[key] = !isNaN(value) ? Number(value) : value;
}

const { useGlobalState, setGlobalState, subscribe } = createGlobalState(initialState);

export { useGlobalState, setGlobalState, subscribe }