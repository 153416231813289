/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Modal,
    CardFooter,
    FormGroup,
    Form,
    Label, Table, ButtonGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ActionColumn from "components/commom/action-column.js";

import ChannelClient from "services/channels";
import dateHelper from "helpers/date";
import {Link, useSearchParams} from "react-router-dom";
import HorizontalSpace from "../../components/HorizontalSpace";

const ChannelIndexView = () => {
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: true,
        hideSizePerPage: true,
    };

    const defaultChannel = {
        id: null,
        name: "",
        api_key: "",
        active: true
    };

    const [channel, setChannel] = useState(defaultChannel);
    const [channels, setChannels] = useState([]);
    const [formModal, setFormModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [secretModal, setSecretModal] = useState(false);
    const [modalAction, setModalAction] = useState("create");
    const channelClient = new ChannelClient();

    const schema = yup
        .object()
        .shape({
            name: yup
                .string()
                .required("O nome é obrigatório")
                .max(100, "O nome deve ter no máximo 100 caracteres"),
            active: yup.boolean().required("Dizer se o objeto está ativo é obrigatório"),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const setChannelDetail = (data) => {
        setChannel(data);
        reset(data);
    };

    const toggleModal = (name) => {
        if (name === "form") {
            setFormModal(!formModal);
        } else if (name === "confirm") {
            setConfirmModal(!confirmModal);
        } else if (name === "secret") {
            setSecretModal(!secretModal);
        }
    };

    const closeAllModal = () => {
        setFormModal(false);
        setConfirmModal(false);
    };

    const clearForm = () => {
        setChannelDetail(defaultChannel);
        reset(defaultChannel);
    };

    const openFormModal = async () => {
        clearForm();
        setModalAction("create");
        toggleModal("form");
    };

    const posSubmit = () => {
        closeAllModal();
        fetchData({ page: 0, sizePerPage: 10 });
        toast.success("Operação realizada com sucesso!");
    };

    const onEdit = async (data) => {
        setModalAction("edit");
        setChannelDetail(data);
        toggleModal("form");
    };

    const onDelete = async (data) => {
        setChannel(data);
        toggleModal("confirm");
    };

    const onDetail = async (data) => {
        setChannelDetail(data);
        setModalAction("detail");
        toggleModal("form");
    };

    const confirmDelete = async () => {
        try {
            await channelClient.exclude(channel.id);
            posSubmit();
        } catch (error) {
            toast.error("Não foi possível deletar o registro.");
        }
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [queryName, setQueryName] = useState("");

    const fetchData = async ({ page, sizePerPage, filter }) => {
        const query = filter ? { query: filter || undefined } : {};
        const response = await channelClient.getAll(page, sizePerPage, query);
        setChannels(response.data);
    };

    const onFilter = async (e) => {
        setSearchParams({name: e.target.value})
        setQueryName(e.target.value)
        await fetchData({ page: 0, sizePerPage: 10, filter: e.target.value });
    };

    useEffect(() => {
        setQueryName(searchParams.get("name"));
        fetchData({ page: 0, sizePerPage: 10, filter: searchParams.get("name") });
    }, []);

    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className={"fa-solid fa-envelopes-bulk"}></i> Canais</h1>
                </Col>
            </Row>

            <Row>
            <div className="col">
                    <Card className="shadow">
                        <CardHeader className="">
                            <Row>
                                <Col md="10">
                                    <input
                                        id="filter"
                                        className="form-control"
                                        placeholder="Buscar"
                                        style={{width: "400px"}}
                                        onChange={onFilter}
                                        value={queryName}
                                    />
                                </Col>
                                <Col md="2" className="text-right">
                                    <Link
                                        className="btn btn-outline-primary"
                                        type="button"
                                        to={"/channels/new"}
                                    >
                                        <span className="btn-inner--icon">
                                          <i className="fa-solid fa-plus" />
                                        </span>
                                        <span className="btn-inner--text">Novo</span>
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className={"horizontal-scroll"}>
                            <table className={"table default-table"}>
                                <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Tipo</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>

                                <tbody>
                                {channels.map((channel, index) => (
                                    <tr key={index}>
                                        <td>{channel.name}</td>
                                        <td style={{width: 0}}><span className={"tag"}>{channel.channel_type}</span></td>
                                        <td style={{width: 0}}>
                                            {channel.channel_type === 'email' && channel.settings.to.map((to, index) => (
                                                <span className={"tag gray"}>{to}</span>
                                            ))}

                                            {channel.channel_type === 'webhook' && (
                                                <span className={"tag gray"}>{channel.settings.url}</span>
                                            )}
                                        </td>
                                        <td style={{width: 0}}>
                                            <ButtonGroup>
                                                <Link to={`/channels/${channel.id}/edit`}
                                                      className={"btn btn-secondary btn-small"}>Editar</Link>
                                                <Button onClick={() => onDelete(channel)}
                                                        className={"btn-danger btn-small"}>Deletar</Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </Row>

            <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={confirmModal}
                toggle={() => toggleModal("confirm")}
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader>
                            <h3 className="mb-0">Confirmação</h3>
                        </CardHeader>
                        <CardBody>Deseja mesmo deletar?</CardBody>
                        <CardFooter className="text-right">
                            <Button
                                color="secondary"
                                type="button"
                                onClick={() => {
                                    toggleModal("confirm");
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button color="danger" type="button" onClick={confirmDelete}>
                                Deletar
                            </Button>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default ChannelIndexView;
