import ReportsClient from "../../services/reports";
import {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from "react-chartjs-2";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import Select from "react-select";
import {useSearchParams} from "react-router-dom";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const reports = new ReportsClient();

function formatNumber(numero) {
    if(!numero || numero === '00') return 0;

    const mil = 1000;
    const milhao = 1000000;
    const bilhao = 1000000000;

    if (numero < mil) {
        return numero.toString();
    } else if (numero < milhao) {
        return `${(numero / mil).toFixed(2)}k`
    } else if (numero < bilhao) {
        return `${(numero / milhao).toFixed(2)}Mi`
    } else {
        return `${(numero / bilhao).toFixed(2)}Bi`
    }
}

const ConsumptionView = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const [startAt, setStartAt] = useState(searchParams.get("startAt") ? dayjs.unix(parseInt(searchParams.get("startAt"))) : dayjs(firstDay));
    const [endAt, setEndAt] = useState(searchParams.get("endAt") ? dayjs.unix(parseInt(searchParams.get("endAt"))) : dayjs(lastDay).subtract(1, 'day'));
    const [datePickerType, setDatePickerType] = useState(searchParams.get("groupBy") || "day");
    const [totalGB, setTotalGB] = useState(0);
    const [totalSpans, setTotalSpans] = useState(0);
    const [totalChecks, setTotalChecks] = useState(0);

    const groupByOptions = [
        {value: "hour", label: "Hora"},
        {value: "day", label: "Dia"},
        {value: "week", label: "Semana"},
        // {value: "month", label: "Mês"}
    ]

    const [groupBy, setGroupBy] = useState(searchParams.get("groupBy") ? groupByOptions.find(g => g.value === searchParams.get("groupBy") ) : groupByOptions[1]);

    const onChangeGroupBy = (option) => {
        setDatePickerType(option.value);
        setGroupBy(option);
        searchParams.set("groupBy", option.value);
        setSearchParams(searchParams);
        fetchAll({ start_at: startAt, end_at: endAt, group_by: option.value })
    }

    const [consuptions, setConsuptions] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [consumptionOptions, setConsumptionOptions] = useState({
        responsive: false,
        maintainAspectRatio: false,
        scales: {},
        plugins: {},
        interaction: {}
    });

    const onChange = (date) => {
        const st = dayjs(date.format("YYYY-MM-01"));
        const et = st.add(1, 'month').subtract(1, 'day');
        setStartAt(st)
        setEndAt(et);

        searchParams.set("startAt", st.unix());
        searchParams.set("endAt", et.unix());
        setSearchParams(searchParams);
        fetchAll({ start_at: st, end_at: et, group_by: groupBy.value })
    }

    const formatGB = (gb) => {
        const value = parseFloat(gb)

        if (value < 1) {
            return (value * 1024).toFixed(0).replace(".", ",") + 'MB'
        }

        if(value < 1024) {
            return (value).toFixed(0).replace(".", ",") + 'GB'
        }

        return (value).toFixed(2).replace(".", ",") + 'TB'
    }

    const fetchAll = async ({ start_at, end_at, group_by }) => {
        reports.getConsumption({ start_at: start_at.unix(), end_at: end_at.unix(), group_by }).then((data) => {
            setConsumptionOptions({
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        // min: parseInt((data['total_count'].min() * 0.9).toFixed(0)),
                        // max: parseInt((data['total_count'].max() * 1.1).toFixed(0)),
                        grid: {
                            display: true
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return value + 'GB';
                            }
                        }
                    },
                    'yRight': {
                        type: 'linear',
                        position: 'right',
                        // min: parseInt((data['total_count'].min() * 0.9).toFixed(0)),
                        // max: parseInt((data['total_count'].max() * 1.1).toFixed(0)),
                        grid: {
                            display: false
                        }
                    },
                    x: {
                        display: false,
                        ticks: {
                            display: false //this will remove only the label
                        }
                    }

                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItems) {
                                const text = tooltipItems.datasetIndex === 0 ? formatGB(tooltipItems.raw) : formatNumber(tooltipItems.raw).replace(".", ",")
                                return tooltipItems.dataset.label + ": " + text
                            }
                        }
                    },
                }
            })

            setConsuptions({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'Ingestão de Dados',
                        data: data['total_gb'],
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false
                    },
                    {
                        label: 'Monitoramento',
                        data: data['total_checks'],
                        borderColor: 'rgb(245,54,92)',
                        yAxisID: 'yRight',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(245,54,92, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            setTotalGB(data['total_gb'].reduce((a, b) => a + b, 0));
            setTotalSpans(data['total_spans'].map(d => parseInt(d)).reduce((a, b) => a + b, 0))
            setTotalChecks(data['total_checks'].map(d => parseInt(d)).reduce((a, b) => a + b, 0))
        });
    }

    useEffect(() => {
        if(!searchParams.get("startAt")) {
            searchParams.set("startAt", startAt.unix());
        }

        if(!searchParams.get("endAt")) {
            searchParams.set("endAt", endAt.unix());
        }

        if(!searchParams.get("groupBy")) {
            searchParams.set("groupBy", groupBy.value);
        }

        setSearchParams(searchParams)

        fetchAll({ start_at: startAt, end_at: endAt, group_by: groupBy.value })
    }, []);

    return (<>
        <Row>
            <Col sm={12}>
                <h1><i className="fa-solid fa-hard-drive"></i> Consumo</h1>
            </Col>
        </Row>

        <Card>
            <CardHeader>
                <Row>
                    {/*<Col sm={2}>*/}
                    {/*    <Select value={groupBy}*/}
                    {/*            classNamePrefix="select"*/}
                    {/*            className={"select-default"}*/}
                    {/*            options={groupByOptions} onChange={onChangeGroupBy} />*/}
                    {/*</Col>*/}
                    <Col sm={4}>
                        <span>Período: </span>
                        <DatePicker
                            picker={"month"}
                            // picker={datePickerType}
                            // showTime={datePickerType === "hour"}
                            format={"MM-YYYY"}
                            size={"large"}
                            onChange={onChange}
                            defaultValue={startAt}
                            separator={"/"}
                            style={{
                                height: '48px',
                                textAlign: 'center',
                                width: '150px',
                                border: '#fafafa'
                            }} />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={3}>
                        <h3>Ingestão de Dados</h3>
                        <p>
                            {formatGB(totalGB)} <small>(10 GB grátis todo mês)</small>
                            <small style={{display: 'block', fontSize: '12px'}}>Ingestão total de dados no período
                                selecionado</small>
                        </p>


                        <h3>Total de Spans</h3>
                        <p>
                            {(formatNumber(totalSpans) || '').replace(".", ",")}
                            <small style={{display: 'block', fontSize: '12px'}}>Ingestão total de spans no período
                                selecionado</small>
                        </p>

                        <h3>Total de Monitoramentos</h3>
                        <p>
                            {(formatNumber(totalChecks) || '').replace(".", ",")} <small>(43k grátis todo mês)</small>
                            <small style={{display: 'block', fontSize: '12px'}}>Total de Monitoramentos de serviços no
                                período
                                selecionado</small>
                        </p>

                    </Col>

                    <Col sm={9}>
                        <div style={{
                            height: '400px',
                            width: '100%'
                        }}>
                            <Bar data={consuptions} options={consumptionOptions}/>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </>)
}

export default ConsumptionView;