/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Modal,
    CardFooter,
    FormGroup,
    Form,
    Label, InputGroup, Input, DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from "reactstrap";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";


import AlarmClient from "services/alarms";
import {Link, useParams} from "react-router-dom";
import ServiceClient from "../../services/service";
import ChannelClient from "../../services/channels";
import Select from "react-select";
import {Divider} from "antd";
import HorizontalSpace from "../../components/HorizontalSpace";


const AlarmFormView = () => {
    const { id: alarmId } = useParams();
    let id = alarmId;

    const alarmClient = new AlarmClient();
    const serviceClient = new ServiceClient();
    const channelsClient = new ChannelClient();

    const metricsOptions = [
        { value: "error_rate", label: "% de Erros" },
        { value: "error_throughput", label: "Quantidade de Erros" },
        { value: "response_time", label: "Tempo de Resposta" },
        { value: "throughput", label: "Quantidade de Execuções" },
    ]

    const operatorOptions = [
        { value: 'greater_than', label: "Maior que" },
        { value: 'greater_than_or_equal', label: "Maior ou igual a" },
        { value: 'less_than', label: "Menor que" },
        { value: 'less_than_or_equal', label: "Menor ou igual a" },
    ]

    const [alarm, setAlarm] = useState({
        name: "",
        metric: "error_rate",
        operator: 'greater_than_or_equal',
        trigger_times: 3,
        wait_times: 3,
        interval_min: 1,
        threshold: 1,
        channels_ids: [],
        services_ids: []
    });
    const [serviceOptions, setServiceOptions] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedChannels, setSelectedChannels] = useState([])
    const [channelOptions, setChannelOptions] = useState([])
    const [selectedMetric, setSelectedMetric] = useState(metricsOptions[0])
    const [selectedOperator, setSelectedOperator] = useState(operatorOptions[3])
    const [metricUnit, setMetricUnit] = useState("%")

    const handleMetrics = (metric) => {
        setAlarm({ ...alarm, metric: metric.value })
        setSelectedMetric(metric)
        switch (metric.value) {
            case "error_rate":
                setMetricUnit('%')
                break
            case "response_time":
                setMetricUnit('ms')
                break
            default:
                setMetricUnit('')
        }
    }

    const handleOperator = (operator) => {
        setAlarm({ ...alarm, operator: operator.value });
        setSelectedOperator(operator);
    }
    const handleSelectService = (data) => {
        setSelectedServices(data);
        setAlarm({ ...alarm, services_ids: data.map((service) => service.value) })
    }

    const handleSelectChannel = (data) => {
        setSelectedChannels(data);
        setAlarm({ ...alarm, channels_ids: data.map((channel) => channel.value) })
    }

    const save = async () => {
        try {
            if(id) {
                await alarmClient.edit(id, alarm).then(() => {
                    toast.success("Atualizado com sucesso!");
                })
            } else {
                const result = await alarmClient.create(alarm);

                if(result) {
                    toast.success("Criado com sucesso!");
                    id = result.id;
                    // window.location.href = `/alarms/${result.id}/edit`;
                }
            }
        } catch (error) {
            toast.error("Erro ao inserir registro.");
        }
    };

    const startForm = (currentAlarm) => {
        setSelectedMetric(metricsOptions.find((metric) => metric.value === currentAlarm.metric))
        setSelectedOperator(operatorOptions.find((operator) => operator.value === currentAlarm.operator))
        serviceClient.getAll(0, 1000).then(({ data }) => {
            setServiceOptions(data.map((service) => ({ value: service.id, label: service.name })))
            setSelectedServices(
                data
                    .filter((service) => currentAlarm.services_ids.includes(service.id))
                    .map((service) => ({ value: service.id, label: service.name }))
            )
        });
        channelsClient.getAll().then(({data}) => {
            setChannelOptions(data.map((channel) => ({ value: channel.id, label: channel.name })))
            setSelectedChannels(
                data
                    .filter((channel) => currentAlarm.channels_ids.includes(channel.id))
                    .map((channel) => ({ value: channel.id, label: channel.name }))
            )
        });
    }

    useEffect(() => {
        if(id) {
            alarmClient.findOne(id).then((data) => {
                setAlarm(data);
                startForm(data)
            })
        } else {
            startForm(alarm)
        }
    }, []);

    return (
        <>
            <Row>
                <Col sm={9}>
                    <Card className="shadow">
                        <CardHeader className="">
                            <Row>
                                <Col md="10">
                                    <h3 className="mb-0">Novo Canal</h3>
                                </Col>
                                <Col md="2" className="text-right">
                                    <Link
                                        className="btn"
                                        type="button"
                                        to={"/alarms"}
                                    >
                                        <span className="btn-inner--icon">
                                          <i className="fa-solid fa-arrow-left" />
                                        </span>

                                        <span className="btn-inner--text">Voltar</span>
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody id={"alarm-form"}>
                            <FormGroup onSubmit={save}>
                                <Row>
                                    <Col sm={12}>
                                        <Label for="name">Nome*</Label>
                                        <Input name={"name"} value={alarm.name} onChange={(e) => setAlarm({ ...alarm, name: e.target.value })} required={true} minLength={4} />
                                    </Col>
                                </Row>

                                <HorizontalSpace />

                                <Row>
                                    <Col sm={6}>
                                        <Label>Serviços</Label>
                                        <Select isMulti value={selectedServices}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                style={{height: '48px'}}
                                                options={serviceOptions} onChange={handleSelectService} />
                                    </Col>

                                    <Col sm={6}>
                                        <Label>Canais</Label>
                                        <Select isMulti value={selectedChannels}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                style={{height: '48px'}}
                                                options={channelOptions} onChange={handleSelectChannel} />
                                    </Col>
                                </Row>

                                <HorizontalSpace />

                                <Row>
                                    <Col sm={12} className="d-flex">
                                        <div className={"list-of-fields"}>
                                            <Label>Métrica</Label>
                                            <Select value={selectedMetric}
                                                    classNamePrefix="select_small"
                                                    options={metricsOptions} onChange={handleMetrics}/>
                                        </div>

                                        <div className={"list-of-fields"}>
                                            <Label>Condição</Label>
                                            <Select value={selectedOperator}
                                                    classNamePrefix="select_small"
                                                    options={operatorOptions}
                                                    onChange={handleOperator}/>
                                        </div>

                                        <div className={"list-of-fields"}>
                                            <Label>Gatilho</Label>
                                            <Input type={"number"}
                                                   value={alarm.threshold}
                                                   style={{width: '100px', display: 'inline-block', marginRight: '5px'}}
                                                   onChange={(e) => setAlarm({...alarm, threshold: e.target.value})}/>
                                            <span style={{display: 'inline-block'}}>{metricUnit}</span>
                                        </div>

                                        <div className={"list-of-fields"}>
                                            <Label title={"Quantidade de vezes que o gatilho deve disparar no Período analisado"}>Recorrência</Label>
                                            <Input type={"number"} min={1} value={alarm.trigger_times}
                                                   onChange={(e) => setAlarm({...alarm, trigger_times: e.target.value})}
                                                   style={{width: '100px'}}/>
                                        </div>

                                        <div className={"list-of-fields"}>
                                            <Label title={"Quantidade de intervalos analisados"}>Período</Label>
                                            <Input type={"number"}
                                                   value={alarm.wait_times}
                                                   min={1}
                                                   onChange={(e) => setAlarm({...alarm, wait_times: e.target.value})}
                                                   style={{width: '100px'}}/>
                                        </div>

                                        <div className={"list-of-fields"}>
                                            <Label style={{display: 'block'}}>Intervalo</Label>
                                            <Input type={"number"}
                                                   style={{width: '100px', display: 'inline-block', marginRight: '5px'}}
                                                   min={1}
                                                   max={5}
                                                   value={alarm.interval_min} onChange={(e) => setAlarm({
                                                ...alarm,
                                                interval_min: e.target.value
                                            })}/>
                                            <small>Min</small>
                                        </div>
                                    </Col>
                                </Row>

                                <HorizontalSpace />

                                <Row>
                                    <Col style={{textAlign: "right"}}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            onClick={save}
                                        >
                                            <span className="btn-inner--icon">
                                              <i className="fa-solid fa-floppy-disk" />
                                            </span>
                                            <span className="btn-inner--text">Salvar</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm={3} className={"form-text-helper"}>
                    <p><strong>Serviços</strong></p>
                    <p>Quais serão os serviços monitorados.</p>

                    <p><strong>Channels</strong></p>
                    <p>Quais destinos serão enviados as notificações.</p>

                    <p><strong>Métrica</strong></p>
                    <p>Qual a métrica da aplicação será monitorada.</p>
                    <ul>
                        <li>% de errors: proporção de erros da aplicação</li>
                        <li>Quantidade de errors: quantidade absoluta de erros</li>
                        <li>Tempo de Resposta: Valor absoluto da latência da aplicação</li>
                    </ul>

                    <p><strong>Condição</strong></p>
                    <p>Como a métrica será monitorada.</p>

                    <p><strong>Gatilho</strong></p>
                    <p>Qual o valor que ao ser combinado com a condição e a métrica dispara o alarm</p>

                    <p><strong>Período</strong></p>
                    <p>Quantidade de intervalos analisados</p>

                    <p><strong>Recorrência</strong></p>
                    <p>Quantidade de vezes que o gatilho deve disparar no intervalo analisado</p>

                    <p><strong>Intervalo</strong></p>
                    <p>Quantos minutos deve durar cada rodada</p>
                </Col>
            </Row>
        </>
    );
};

export default AlarmFormView;
