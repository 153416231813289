import axios from "axios";
import { toast } from "react-toastify";

const onError = (error) => {
  if ([403].includes(error.response.status)) {
    localStorage.clear();
    window.location.href = "/auth/login";
    setTimeout(() => {
      toast.warn("Sessão expirada, faça login novamente.");
    }, 1000);
    return;
  }
  throw error;
};

class ApiClient {
  constructor(data) {
    axios.defaults.baseURL =
      data?.baseUrl || process.env.REACT_APP_API_BASE_URL;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async get(url) {
    try {
      const { data } = await axios.get(url);
      return data;
    } catch (error) {
      onError(error);
    }
  }

  async post(url, body) {
    try {
      const { data } = await axios.post(url, body);
      return data;
    } catch (error) {
      onError(error);
    }
  }

  async patch(url, body) {
    try {
      const { data } = await axios.patch(url, body);
      return data;
    } catch (error) {
      onError(error);
    }
  }

  async delete(url) {
    try {
      const { data } = await axios.delete(url);
      return data;
    } catch (error) {
      onError(error);
    }
  }
}

export default ApiClient;
